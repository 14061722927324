import React, { useState, useEffect, useMemo } from "react";
import { withFirebase } from "../../../utils/Firebase";
import Testimony from "./atoms/Testimony";
import MinistrySelect from "../../molecules/ministrySelect";
import Divider from "../../atoms/Divider";

import ReactPlayer from "react-player";

const Testimonies = ({ firebase }) => {
  const [selectedMinistry, setSelectedMinistry] = useState();
  const [testimonies, setTestimonies] = useState();

  useEffect(() => {
    if (selectedMinistry) {
      getTestimonies(selectedMinistry);
    }
  }, [selectedMinistry]);

  const getTestimonies = async (ministryId) => {
    await firebase
      .testimonies()
      .where("ministryId", "==", ministryId)
      .get()
      .then((querySnapshot) => {
        let array = [];
        querySnapshot.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
          array.push(doc.data());
        });
        array.sort((a, b) => {
          return a.timestamp > b.timestamp
            ? -1
            : a.timestamp === b.timestamp
            ? 0
            : 1;
        });
        setTestimonies(array);
      })
      .catch((err) => console.log(err));
  };

  return (
    <div className="home container">
      <div className="ministry__details">
        <h1 className="ministry__title">Testimonies</h1>
      </div>

      <p className="ministry__body">
        Users can create their own testimonies within the Share Bibles app and
        upload them to the database. Any testimonies that are submitted by teams
        in your ministry will appear below. You can use the video, audio, images
        and text for your own promotional purposes.
      </p>

      <div className="videoPlayer">
        <ReactPlayer
          url={"https://youtu.be/K1d5eDjGekw"}
          // playing
          controls
        />
      </div>
      <p>
        <strong>Downloads:</strong> click the links to download any video/audio
        files available in a testimony
      </p>
      <Divider />
      <div className="map_select_div" key="countrySelect-1">
        <MinistrySelect
          selectedMinistry={selectedMinistry}
          setSelectedMinistry={setSelectedMinistry}
        />
      </div>
      {testimonies && (
        <div className="testimonies">
          {testimonies.map((testimony) => (
            <Testimony data={testimony} />
          ))}
        </div>
      )}
    </div>
  );
};

export default withFirebase(Testimonies);
