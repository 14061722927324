import React from "react";
import { compose } from "recompose";
import Layout from "../utils/layout";
import { withAuthorization, withEmailVerification } from "../utils/Session";
import Testimonies from "../components/scenes/Testimonies";

const condition = (authUser) => !!authUser;
const TestimoniesPage = compose(
  withEmailVerification,
  withAuthorization(condition)
)(Testimonies);

export default () => {
  return (
    <Layout>
      <TestimoniesPage />
    </Layout>
  );
};
