import React, { useState } from "react";
import { timeConverter } from "../../../../scripts/getTime";
import { getLanguageString } from "../../../../scripts/getLanguageString";
import { YouTube, MusicNote, Image, MenuBook } from "@material-ui/icons";
import Colors from "../../../../constants/Colors";

const getFileIcon = (type) => {
  if (type === "video") {
    return <YouTube style={{ color: Colors.primary }} />;
  }
  if (type === "audio") {
    return <MusicNote style={{ color: Colors.primary }} />;
  }
  if (type === "image") {
    return <Image style={{ color: Colors.primary }} />;
  }
  if (type === "text") {
    return <MenuBook style={{ color: Colors.primary }} />;
  }
  return type;
};

const Testimony = ({ data }) => {
  const [showMore, setShowMore] = useState();
  return (
    <div className="testimony">
      <div className="testimony__info">
        <div className="testimony__info__name">{data.name}</div>
        <div className="testimony__info__timestamp">
          {timeConverter(data.timestamp)}
        </div>
        {data.testimonyLanguage && (
          <div className="testimony__info__language">
            {getLanguageString(data.testimonyLanguage)}
          </div>
        )}
        <div className="testimony__files">
          {["image", "text"].map((type) => {
            if (!data.files[type]) return null;
            if (type === "image")
              return (
                <img
                  src={data.files[type]}
                  alt="Testimony Image"
                  class="responsiveImage"
                />
              );
            if (type !== "text") {
              return (
                <a
                  className="testimony__files__type"
                  href={data.files[type]}
                  target="_blank"
                >
                  {getFileIcon(type)}
                  {` ${type.toUpperCase()}`}
                </a>
              );
            }
            if (type === "text") {
              const fullTextString = data.files[type];
              const initialText =
                fullTextString.length > 100
                  ? fullTextString.substring(0, 99) + "..."
                  : fullTextString;
              return (
                <div>
                  <p>{showMore ? fullTextString : initialText}</p>
                  {fullTextString.length > 100 && (
                    <p
                      className="testimony__files__type__showMore"
                      onClick={() => setShowMore(!showMore)}
                    >
                      {!showMore ? "SHOW MORE" : "HIDE"}
                    </p>
                  )}
                </div>
              );
            }
          })}
          {(!!data.files.audio || !!data.files.video) && (
            <div className="testimony__files__downloads">
              <p className="testimony__files__downloads__title">Downloads</p>
              {["audio", "video"].map((type) => {
                if (!data.files[type]) return null;
                return (
                  <a
                    className="testimony__files__type"
                    href={data.files[type]}
                    target="_blank"
                  >
                    {getFileIcon(type)}
                    {` ${type.toUpperCase()}`}
                  </a>
                );
              })}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Testimony;
