import moment from 'moment';

// eslint-disable-next-line import/prefer-default-export
export const timeConverter = timestamp => {
  return moment
    .utc(+timestamp)
    .clone()
    .local()
    .format('DD-MM-YYYY (HH:mm)');
};
